import React, { useRef } from 'react'
import AppIcon from '../../assets/AppIcon'
import AppHelpers from '../../js/Apphelpers'
import { AppButton, AppHeader, ReqBarStage } from '../Components'
import RequestFunction from './_RequestFunction'


export default () => {
   const [cState, setCState] = React.useState({
      addrList: [],
      showAddrModal: false, loader: false
   })
   let inputRef = useRef()
   return (
      <div>
         <div className="container">
            <AppHeader />
            <ReqBarStage active={1} title="Please provide details for this job request" />
            <div className="row mt-4">
               <div className="col-8 col-lg-6" style={{ position: 'unset' }}>
                  <div >
                     <p className="position-relative">
                        <span>Your Job Location (eg Yaba, Ikeja etc)</span>
                        <input className="form-control" onChange={e => {
                           let txt = e.target.value?.trim()
                           if (txt) {
                              RequestFunction.SearchGoogleLocation(setCState, txt)
                           }
                        }} ref={inputRef} onFocus={() => setCState(d => ({ ...d, showAddrModal: true }))} />
                        {cState?.loader && <img src={AppIcon.loaderGif} className="gif-loader" />}
                     </p>
                     {cState?.showAddrModal &&
                        <React.Fragment>
                           <div className="" onClick={() => setCState(d => ({ ...d, showAddrModal: false }))}
                              style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}></div>
                           <div style={{ position: 'relative' }} className="">
                              <div className="rq-addr-modal">
                                 {cState?.addrList?.map((e, i) => <div key={i}
                                    onClick={() => {
                                       inputRef.current.value = e?.description
                                       setCState(d => ({ ...d, showAddrModal: false, loader: true }))
                                       RequestFunction.getGeoCoordinates(e?.description,
                                          res => setCState(d => ({ ...d, ...res, loader: false })))
                                    }}>{e?.description}</div>)}
                              </div>
                           </div>
                        </React.Fragment>
                     }
                  </div>
                  <p>
                     <span>Preferred Start Date</span>
                     <input className="form-control" onChange={e => {
                        cState.prefer_date = (e.target.value)?.substring(0, 10)
                     }} type="date" min={new Date().toISOString().substring(0, 10)}
                     />
                  </p>

                  <p>
                     <span>Description</span>
                     <textarea className="form-control" onChange={txt => {
                        cState.description = txt.target.value?.trim()
                     }} rows={5}></textarea>
                  </p>
                  <div className="mt-4">
                     <AppButton title="Next" onClick={() => RequestFunction.requestDataToState({
                        geo: { longitude: cState?.longitude, latitude: cState?.latitude },
                        address: cState?.address,
                        date: cState?.prefer_date, description: cState?.description
                     })} />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}