import React from "react";
import { Link } from "react-router-dom";
import AppIcon from "../../assets/AppIcon";
import AppContext, { appState } from "../../js/Store";
import { AppButton, AppHeader, AppWallet } from "../Components";
import WalletFunction from "../Wallet/_WalletFunction";
import Apphelpers from '../../js/Apphelpers'



const DefaultCompo = () => {
   let acc = appState.account
   React.useEffect(() => {
      WalletFunction.getWalletBalance()
   }, [])

   let dData = appState.results.wallets

   return (
      <div>
         <div className="container">
            <AppHeader />
            <div className="row">
               <div className="col-lg-6">
                  <div className="mb-4">
                     <div className="acc-dp" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <img src={acc?.avatar} />
                     </div>
                  </div>
                  <Link to="/app/accounts/bio" className="rq-skill-cat acc-nav">Personal Details</Link>
                  {/* <Link to="/app/accounts/bio" className="rq-skill-cat acc-nav">Skills Management</Link>
                  <Link to="/app/accounts/bio" className="rq-skill-cat acc-nav">Work Location</Link> */}
                  {/* <Link to="/app/accounts/bio" className="rq-skill-cat acc-nav">Bank Details</Link> */}
                  <Link to="/app/accounts/verification" className="rq-skill-cat acc-nav">Verification</Link>
                  <div className="rq-skill-cat acc-nav" style={{ color: 'red' }} onClick={() => {
                     localStorage.removeItem('_ud')
                     Apphelpers.navigate('/')
                  }}>Logout</div>
               </div>
            </div>
         </div>
      </div>)
}



export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)