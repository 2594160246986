import React from 'react'
import AppIcon from '../../assets/AppIcon'
import { AppButton, AppHeader, ReqBarStage } from '../Components'



export default () => {


   return (
      <div>
         <div className="container">
            <AppHeader />
            <ReqBarStage active={3} title="Select a preferred worker" />
            <div className="row">
               <div className="col-sm-6 col-md-4">
                  <span>Job Location</span>
                  <span>Yaba, Lagos</span>
               </div>
               <div className="col-sm-6 col-md-4">
                  <span>Category</span>
                  <span>Web Development</span>
               </div>
               <div className="col-sm-6 col-md-4">
                  <span>Category</span>
                  <span>Web Development</span>
               </div>
               <div className="col-sm-6 col-md-4">
                  <span>Preferred Date</span>
                  <span>Sept 23, 2020</span>
               </div>
               <div className="col-sm-6 col-md-4">
                  <span>Job Description</span>
                  <span>Everything inside a component will be mounted automatically on the map And it will be automatically unmounted from the map if you don't render it.</span>
               </div>
            </div>
            <div style={{ marginLeft: 'auto', maxWidth: 200, marginTop: 50 }}>
               <AppButton />
            </div>
         </div>
      </div>
   )
}