import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppIcon from '../../assets/AppIcon'
import AppHelpers from '../../js/Apphelpers'
import { appState } from '../../js/Store'
import { AppButton, AppHeader, AppModal, ReqBarStage } from '../Components'
import RequestFunction from './_RequestFunction'




const SkillCategory = ({ data, onClick }) => (
   <div className="col-md-12 mt-3 cursor-pointer" onClick={onClick}>
      <div className="rq-skill-cat">
         <div className="d-flex" >
            {/* <img src={AppIcon.successIcon} style={{ width: 30, marginRight: 15 }} /> */}
            <span className="text-capitalize">{data?._id}</span>
         </div>
         <img src={AppIcon.arrowDown} style={{ width: 30, height: 30 }} />
      </div>
   </div>
)



export default () => {
   const [cState, setCState] = React.useState({
      title: '', skillCategory: null,
      skills: [], loader: false, catName: ''
   })

   let rqData = appState.rq.data_tem
   let navigate = useNavigate()


   React.useEffect(() => {
      if (!rqData || !rqData?.address) {
         navigate('/app/request')
      }

      //if there's no data, move back
      RequestFunction.getSkills(undefined, "category_group", undefined, (res, error) => {
         if (!rqData?.address) return
         if (res instanceof Array) {
            setCState(d => ({ ...d, skillCategory: res }))
         } else {
            setCState(d => ({ ...d, skillCategory: [] }))
         }
      })
   }, [])
   // console.log(rqData)
   return (
      <div>
         <div className="container">
            <AppHeader />
            <ReqBarStage active={2} title="What kind of service do you require?" />
            <div className="row mt-4">
               <div className="col-8 col-lg-6">
                  <p>
                     <span>Search Job Task</span>
                     <input className="form-control" />
                  </p>
                  <p>Or choose from one of the following categories</p>
               </div>
            </div>
            <div className="row">
               <div className="col-sm-6">
                  <div className="row">
                     {cState?.skillCategory?.map((e, i) => <SkillCategory data={e} key={i}
                        onClick={() => setCState(d => ({ ...d, skills: e?.skills, catName: e?._id }))} />)}
                  </div>
               </div>
               <div className="col-sm-6 rq-skill-list">
                  {cState?.skills?.length > 0 &&
                     <React.Fragment>
                        <p className="text-capitalize text-bold">{cState?.catName}</p>
                        <div className="rq-addr-modal rq-skill-list-row">
                           {cState?.skills?.map((e, i) => <div key={i} onClick={() => RequestFunction.searchWorker({ ...rqData, skill: e })}
                              className="text-capitalize">{e}</div>)}
                        </div>
                     </React.Fragment>
                  }
               </div>
            </div>
            <AppModal>
               <img src={AppIcon.HollaLoader} className="rq-loader" />
            </AppModal>
         </div>
      </div>
   )
}