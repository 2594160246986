import AppConfig from "../../js/AppConfig"
import AppHelpers from "../../js/Apphelpers"
import { appState } from "../../js/Store"


const WalletFunction = {}

WalletFunction.getWalletTransaction = () => {
   let sendData = {
      url: AppConfig.url.getWalletTrans
   }

   let dData = appState?.results?.wallets
   //if there's data stop the request
   if (dData?.balance instanceof Array) return

   //send the request to the sever
   AppHelpers.sendRequest(sendData, (res, error) => {
      //if there's an error
      if (res instanceof Array) {
         appState.results.wallets.transactions = res
      } else if (res?.error) {
         return AppHelpers.flashMessage(res.error)
      } else {
         return AppHelpers.flashMessage("An error occurred try again later")
      }
      AppHelpers.updateState()
   })
}

WalletFunction.getWalletBalance = () => {
   let dData = appState?.results?.wallets
   //if there's data stop the request
   if (!isNaN(dData?.balance)) return

   let sendData = {
      url: AppConfig.url.getWalletBalance
   }
   //send the request to the sever
   AppHelpers.sendRequest(sendData, (res, error) => {
      console.log(res)
      //if there's an error
      if (res?.status) {
         appState.results.wallets.balance = res?.balance
         appState.results.wallets.earning = res?.earning
      } else if (res?.error) {
         return AppHelpers.flashMessage(res.error)
      } else {
         return AppHelpers.flashMessage("An error occurred try again later")
      }
      AppHelpers.updateState()
   })
}

export default WalletFunction;