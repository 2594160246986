import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import AppIcon from '../../assets/AppIcon'
import AppHelpers from '../../js/Apphelpers'
import AppContext, { appState } from '../../js/Store'
import { AppButton, AppHeader, ReqBarStage } from '../Components'
import RequestFunction from './_RequestFunction'



const ProfileCard = ({ data, }) => {
   const [cState, setCState] = React.useState({ openMore: false })
   return (
      <div className="col-sm-12">
         <div className="rq-skill-cat" style={{ boxShadow: 'unset' }}>
            <div className="d-flex">
               <img className="rq-wrkr-dp" src={data?.avatar} />
               <div>
                  <span>{data?.name}</span>
                  <span className="d-block text-capitalize">{data?.skill}</span>
               </div>
            </div>
         </div>
      </div>
   )
}




const DefaultCompo = () => {
   let navigate = useNavigate()

   let workerList = appState?.rq?.data_tem
   React.useEffect(() => {
      if (!workerList || !workerList.address) {
         console.log(workerList)
         navigate('/app/request')
      }
   }, [])

   return (
      <div>
         <div className="container">
            <AppHeader />
            <ReqBarStage active={4} title="Request Preview" />
            <div className="row mt-4">
               <ProfileCard data={workerList?.worker_data} />
               <div className="col-sm-6 col-md-4 col-lg-3">
                  <span className="text-gray">Job Location</span>
                  <p>{workerList?.address}</p>
               </div>
               <div className="col-sm-6 col-md-4 col-lg-3">
                  <span className="text-gray">Preferred Date</span>
                  <p>{workerList?.date}</p>
               </div>
               <div className="col-12">
                  <span className="text-gray">Description</span>
                  <p>{workerList?.description}</p>
               </div>
               <div className="col-sm-6">
                  {workerList?.status_code === 1 ? <div className="rq-note">Kindly wait for <span>{workerList?.worker_data?.name}</span> to attend to your request</div>
                     : <AppButton title={appState?.loader ? "Loading..." : "Send Request"} disabled={appState?.loader ? true : false}
                        onClick={() => RequestFunction.requestAWorker(workerList)} />}
               </div>
            </div>
         </div>
      </div>
   )
}


export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)