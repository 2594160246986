import React from "react";
import { Link } from "react-router-dom";
import AppIcon from "../../assets/AppIcon";
import AppContext, { appState } from "../../js/Store";
import { AppButton, AppHeader, AppWallet, } from "../Components";



const DefaultCompo = () => (
   <div>
      <div className="container">
         <AppHeader />
         <div className="row mt-5">
            <div className="col-sm-6">
               <p className="text-center mt-5">No verification Data</p>
            </div>
         </div>
      </div>
   </div>
)

export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)