import React from 'react';
import { NotificationContainer } from 'react-notifications';
import logo from './logo.svg';
import 'react-notifications/lib/notifications.css';
import './App.css';
import { useNavigate, useRoutes } from 'react-router-dom';
import { AppRouter } from './router';
import { AppProvider } from './js/Store';
import AppHelpers from './js/Apphelpers';



export default () => {
   let appRouting = useRoutes(AppRouter)
   return (
      <AppProvider>
         {appRouting}
         <NotificationContainer />
      </AppProvider>
   )
};
