import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppIcon from "../../assets/AppIcon";
import AppHelpers from "../../js/Apphelpers";
import AppContext, { appState } from "../../js/Store";
import { AppButton } from "../Components";
import OnBoardingFunction from "./_OnboardingFunction";



const DefaultCompo = () => {
   const [cState, setCState] = React.useState({ i1: '', i2: '', i3: '', i4: '' })
   AppHelpers.navigate = useNavigate()
   let acc = appState?.account
   let input1 = useRef()
   let input2 = useRef()
   let input3 = useRef()
   let input4 = useRef()
   React.useEffect(() => {
      if (acc?.status !== "otp") {
         AppHelpers.navigate('/')
      }
   }, [])
   return (
      <div>
         <div className="middle-page">
            <div className="login-m-page" style={{ width: '300px' }}>
               <div className="text-center">
                  <img src={AppIcon.HollaLogo} className="app-logo" />
               </div>
               <div className="text-center" style={{ margin: '20px 0' }}>
                  <div>Please type the code sent to <span className="text-blue">{acc?.email}</span></div>
               </div>
               <div className="form-group mt-5" >
                  <div style={{ display: 'flex', marginBottom: 90, justifyContent: 'space-between' }}>
                     <input className="form-control text-center" onKeyUp={e => {
                        if (/^\d+$/.test(e.nativeEvent.key)) {
                           input2.current.focus()
                           cState.i1 = e.nativeEvent.target.value
                        } else if (e.nativeEvent.key === "Backspace") {
                           // input2.current.focus()
                        }
                     }} maxLength={1} style={{ width: 50 }} ref={input1} />
                     <input className="form-control text-center" ref={input2} maxLength={1} onKeyUp={e => {
                        if (/^\d+$/.test(e.nativeEvent.key)) {
                           input3.current.focus()
                           cState.i2 = e.nativeEvent.target.value
                        } else if (e.nativeEvent.key === "Backspace") {
                           input1.current.focus()
                        }
                     }} style={{ width: 50 }} />
                     <input className="form-control text-center" ref={input3} onKeyUp={e => {
                        if (/^\d+$/.test(e.nativeEvent.key)) {
                           input4.current.focus()
                           cState.i3 = e.nativeEvent.target.value

                        } else if (e.nativeEvent.key === "Backspace") {
                           input2.current.focus()
                        }
                     }} maxLength={1} style={{ width: 50 }} />
                     <input className="form-control text-center" ref={input4} onKeyUp={e => {
                        if (/^\d+$/.test(e.nativeEvent.key)) {
                           cState.i4 = e.nativeEvent.target.value

                        } else if (e.nativeEvent.key === "Backspace") {
                           input3.current.focus()
                        }
                     }} maxLength={1} style={{ width: 50 }} />
                  </div>
                  <AppButton disabled={appState?.loader ? true : false} title={appState?.loader ? "Loading...." : "submit"} onClick={() => OnBoardingFunction.submitOTP(`${cState.i1}${cState.i2}${cState.i3}${cState.i4}`)} />
                  <div className="text-center mt-4">
                     <span className="mr-2">Didn't receive OTP?</span>
                     <a href="javascript:void()" style={{ textDecoration: 'underline' }} onClick={() => OnBoardingFunction.resendOTPCode()}>Resend</a>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}


export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)