import React from 'react'
import { useNavigate, } from 'react-router-dom'
import AppIcon from '../../assets/AppIcon'
import { appState } from '../../js/Store'
import { AppButton, AppHeader, ReqBarStage } from '../Components'
import RequestFunction from './_RequestFunction'




const WorkerList = ({ data }) => {
   const [cState, setCState] = React.useState({ openMore: false })
   return (
      <div className="col-md-4 mt-3">
         <div>
            <div className="rq-skill-cat" style={{ flexDirection: 'column' }} onClick={() => setCState(d => ({ ...d, openMore: !cState?.openMore }))}>
               <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                  <div className="d-flex">
                     <img className="rq-wrkr-dp" src={data?.avatar} />
                     <div>
                        <span>{data?.name}</span>
                        <span className="d-block">{data?.skill?.name}</span>
                     </div>
                  </div>
                  <img src={AppIcon.arrowDown} style={{ width: 30, height: 30 }} />
               </div>
               {cState?.openMore &&
                  <div className="text-center">
                     <p className="mt-3 text-left">{data?.skill?.description}</p>
                     <AppButton title="Select" onClick={() => RequestFunction.addSelectedWorkerToState(data)}
                        style={{ maxWidth: 300, margin: '10px auto' }} />
                  </div>
               }
            </div>
         </div>
      </div>
   )

}



export default () => {

   let workerList = appState?.rq?.worker_list

   let navigate = useNavigate()



   React.useEffect(() => {
      if (!workerList || !(workerList instanceof Array)) {
         navigate('/app/request')
      }
   }, [])

   console.log(workerList);

   return (
      <div>
         <div className="container">
            <AppHeader />
            <ReqBarStage active={3} title="Select a preferred worker" />
            <div className="row">
               {workerList instanceof Array && workerList.length > 0 ? workerList.map((e, i) => <WorkerList data={e} key={i} />) : <div>No Worker</div>}
            </div>
            {/* <div style={{ marginLeft: 'auto', maxWidth: 200, marginTop: 50 }}>
               <AppButton />
            </div> */}
         </div>
      </div>
   )
}