import React from "react";
import { Link } from "react-router-dom";
import MaterialTable from 'material-table'
import AppIcon from "../../assets/AppIcon";
import { AppButton, AppHeader, AppWallet, TableMaterialIcon } from "../Components";
import WalletFunction from "./_WalletFunction";
import AppContext, { appState } from "../../js/Store";
import AppHelpers from "../../js/Apphelpers";



const DefaultCompo = () => {
   React.useEffect(() => {
      WalletFunction.getWalletTransaction()
      WalletFunction.getWalletBalance()
   }, [])

   let dData = appState?.results?.wallets
   // console.log(dData)
   return (
      <div>
         <div className="container bg-page">
            <AppHeader />
            {/* <div>
               <span className="text-gray">Hello!</span>
               <div style={{ fontSize: 35, fontWeight: '600' }}>Ealy</div>
            </div> */}
            <AppWallet title="Wallet Balance" amount={AppHelpers.formatMoney(dData?.balance ?? 0)} />
            <div className="mb-5"></div>
            <MaterialTable
               data={dData?.transactions ?? []}
               isLoading={!(dData?.transactions instanceof Array)}
               style={{ boxShadow: 'none' }}
               icons={TableMaterialIcon}
               actions={[]}
               columns={[
                  {
                     title: "Amount",
                     field: "amount",
                     render: rowData => <div>&#8358;{AppHelpers.formatMoney(rowData?.amount)}</div>
                  },
                  {
                     title: "Description",
                     field: "body",
                     render: rowData => <div>{rowData?.title}-{rowData?.body}</div>
                  },
                  {
                     title: "Method",
                     field: "method",
                  },
                  {
                     title: "Date",
                     field: "createdAt",
                     render: rowData => AppHelpers.calculatePostDate(rowData?.createdAt)
                  },
                  {
                     title: "Status",
                     field: "method",
                     headerStyle: { textAlign: "center" },
                     render: () => (
                        <div className="text-center">
                           <img src={AppIcon.successIcon} style={{ width: 30 }} />
                           <div>Success</div>
                        </div>
                     )
                  },
               ]}
               options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  headerStyle: { fontWeight: 'bold', }
               }}
               title="Wallet Transactions"
            />
         </div>
      </div>
   )
}


export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)