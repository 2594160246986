import ReducerAction from './ReducerActions';
export default (state, action) => {
   switch (action.type) {
      case ReducerAction.updateState:
         return {
            ...state,
            cUpdate: Math.random()
         }

      default:
         return state
   }

}


