import { Navigate, Outlet, useLocation, useNavigate, } from "react-router-dom"
import Home from "./pages/Dashboard/Home"
import Login from "./pages/Onboarding/Login"
import Wallet from "./pages/Wallet/Wallets"
import Register from "./pages/Onboarding/Register"
import Jobs from "./pages/JobRequest/Jobs"
import AppHelpers from "./js/Apphelpers"
import { appState } from "./js/Store"
import React from "react"
import OnBoardingFunction from "./pages/Onboarding/_OnboardingFunction"
import ConfirmLogin from "./pages/Onboarding/ConfirmLogin"
import ReqLocation from "./pages/JobRequest/ReqLocation"
import ReqSkillCategory from "./pages/JobRequest/ReqSkillCategory"
import ReqWorkerList from "./pages/JobRequest/ReqWorkerList"
import ReqWorkerProfile from "./pages/JobRequest/ReqWorkerProfile"
import OTP from "./pages/Onboarding/OTP"
import Account from "./pages/Account"
import BioData from "./pages/Account/BioData"
import InviteFriends from "./pages/Dashboard/InviteFriends"
import Verification from "./pages/Account/Verification"
import ReqPreview from "./pages/JobRequest/ReqPreview"



export const AppCompo = () => {
   AppHelpers.navigate = useNavigate()
   let useParam = useLocation()
   //if the user is not login, navigate him away to confirm logion
   if (!appState?.is_login) {
      return <Navigate to="/confirm-login" state={{ redirect: useParam?.pathname }} />
   }
   return (
      <div className="container-fluid p-0">
         <div className="app-row">
            {/* <AppSiderBar /> */}
            <div className="main-page">
               {/* <AppHeader /> */}
               <Outlet />
            </div>
         </div>
      </div>
   )
}

export const AppRouter = [{
   path: "/",
   element: <Login />
}, {
   path: "/register",
   element: <Register />,
}, {
   path: "/otp",
   element: <OTP />,
},
{
   path: "/confirm-login",
   element: <ConfirmLogin />,
},
{
   path: "/app",
   element: <AppCompo />,
   children: [
      {
         path: "accounts",
         children: [{
            path: "verification",
            element: <Verification />
         }, {
            path: "bio",
            element: <BioData />
         }, {
            path: "",
            element: <Account />,
         }
         ]
      }, {
         path: "request",
         children: [
            {
               path: "skill-category",
               element: <ReqSkillCategory />
            }, {
               path: "worker-list",
               element: <ReqWorkerList />
            }, {
               path: "worker-profile",
               element: <ReqWorkerProfile />
            }, {
               path: "request-preview",
               element: <ReqPreview />,
            }, {
               path: "",
               element: <ReqLocation />,
            }
         ]
      }, {
         path: "wallets",
         element: <Wallet />
      },
      {
         path: "rewards",
         element: <InviteFriends />
      },
      {
         path: "jobs",
         element: <Jobs />
      }, {
         path: "",
         element: <Home />,
      }]
}]
