import AppConfig from "../../js/AppConfig"
import AppHelpers from "../../js/Apphelpers"
import ReducerActions from "../../js/ReducerActions"
import { appState } from "../../js/Store"

const OnBoardingFunction = {}

//for logining in
OnBoardingFunction.processLogin = (data) => {
   //check if the values are not there
   if (!data?.email || !data?.email === "") {
      return AppHelpers.flashMessage('Email is required')
   }
   if (!data?.password || !data?.password === "" || data?.password.length < 4) {
      return AppHelpers.flashMessage('Password is required. Must be more than 4 characters')
   }

   let sendData = {
      url: AppConfig.url.login,
      method: 'POST',
      token: 'JJEKXMEITNDEJNGEJ355434JFJFDNT',
      body: data
   }

   appState.loader = ReducerActions.loader.signUp
   AppHelpers.updateState()

   //send the request to the sever
   AppHelpers.sendRequest(sendData, (res, error) => {
      appState.loader = false
      //if there's an error
      if (res?.error) {
         //if there's verified
         if (res?.verified === false) {
            appState.account = { email: data?.email, status: "otp" }
            return AppHelpers.navigate('/otp')

         }
         AppHelpers.flashMessage(res.error)
      } else if (res?.status) {
         // console.log(appState)
         appState.is_login = true
         appState.account = res
         AppHelpers.userData = res
         AppHelpers.localStorageSave('_ud', res)
         AppHelpers.navigate('/app/', { replace: true })
         return
      } else {
         AppHelpers.flashMessage("An error occurred try again later")
      }
      AppHelpers.updateState()
   })
}


OnBoardingFunction.confirmLogin = (pathname) => {
   let getData = AppHelpers.getUserData()
   //if ther's token, set the account data to state
   if (getData?.token) {
      appState.is_login = true
      appState.account = getData
      // console.log("it's here again")
      // console.log(pathname)
      AppHelpers.navigate(pathname)
      // AppHelpers.updateState()
   } else {
      AppHelpers.navigate('/')
   }
}

//for registration
OnBoardingFunction.register = (data) => {
   let name = data?.name
   let email = data?.email
   let phone = data?.phone
   let term = data?.term
   let refcode = data?.refcode
   let password = data?.password
   let cPassword = data?.cPassword


   //check if the values are not there
   if (!name || name === "") {
      return AppHelpers.flashMessage('A valid name is required')
   }

   let sName = name.split(" ");

   //check the length
   if (sName.length < 2) {
      return AppHelpers.flashMessage('First & Last name are required')
   }
   //check the length
   if (sName.length > 2) {
      return AppHelpers.flashMessage('Only First & Last name are required')
   }

   //check the length of each name
   if (sName[0].length < 2) {
      return AppHelpers.flashMessage("Really?? Your first name is one letter? No that can not pass")
   }

   if (sName[1].length < 2) {
      return AppHelpers.flashMessage("Really?? Your last name is one letter? No that can not pass")
   }

   //if the name is two short
   if (name.length < 4) {
      return AppHelpers.flashIndicator("First and Last name can not be this short")
   }

   if (!phone || phone.length !== 11) {
      return AppHelpers.flashMessage('Phone number is required. Must be 11 digit')
   }

   //check if there's refcode
   if (refcode && refcode.length !== 6) {
      return AppHelpers.flashMessage('Invalid referal code')
   }

   //if the user type is not captured
   if (["client", "worker"].indexOf(data?.user_type) === -1) {
      return AppHelpers.flashMessage("Your selection was not captured. Please restart the app")
   }

   //validate the email
   if (!email || !email.includes('@') || !email.includes('.')) {
      return AppHelpers.flashMessage('A valid email is required')
   }

   //the terms and condition
   if (term !== true) {
      return AppHelpers.flashMessage('Please accept our terms and conditions')
   }

   //check if the values are not there
   if (!password || !password === "" || password.length < 4) {
      return AppHelpers.flashMessage('Password is required. Must be more than 4 characters')
   }
   // //chec if the passwords are the same
   // if (password !== cPassword) {
   //    return AppHelpers.flashMessage('Password do not match')
   // }

   appState.loader = ReducerActions.loader.signUp
   AppHelpers.updateState()

   let sendData = {
      url: AppConfig.url.register,
      method: 'POST',
      token: 'JJEKXMEITNDEJNGEJ355434JFJFDNT',
      body: { name, email, phone, term, refcode, password, user_type: data?.user_type }
   }
   //send the request to the sever
   AppHelpers.sendRequest(sendData, (res, error) => {
      // console.log(res)
      // console.log(error)
      appState.loader = false
      //if there's an error
      if (res.error) {
         AppHelpers.flashMessage(res.error)
      } else if (res.status) {
         // res.token_free = password
         appState.account = res //add the new data to the state
         AppHelpers.localStorageSave('_ud', { ...res, status: 'otp' });
         AppHelpers.navigate('/otp')
      } else {
         AppHelpers.flashMessage('An error occurred')
      }
      AppHelpers.updateState()
   })
}


//for resending OTP code
OnBoardingFunction.resendOTPCode = (action, callback) => {
   let email = action?.email ?? appState?.account?.email
   //check if there's no email found
   if (!email || email.indexOf('@') === -1) {
      return AppHelpers.flashMessage('Account not found')
   }
   let sendData = {
      url: AppConfig.url.resendOtp,
      method: 'POST',
      token: 'JJEKXMEITNDEJNGEJ355434JFJFDNT',
      body: { email, action: action?.action }
   }
   appState.loader = ReducerActions.loader.signUp
   AppHelpers.updateState()
   //send the request to the server
   AppHelpers.sendRequest(sendData, res => {
      appState.loader = null;
      if (res.error) {
         AppHelpers.flashMessage(res.error)
      } else if (res.status) {
         //if the request is to change passowrd
         if (action?.action === 'change_password') {
            if (typeof appState.account !== "object") {
               appState.account = {}
            }
            appState.account.email = email //add the email to the state
            appState.account.action = "change_password"
            AppHelpers.localStorageSave('_ud', { email, status: 'otp', action: 'change_password' });
            AppHelpers.navigate('/otp')
         } else {
            //if callback is a function, invoke it
            if (typeof callback === "function") {
               callback(res)
            }
            AppHelpers.flashMessage('OTP has been re-sent to your email')
         }
      } else {
         AppHelpers.flashMessage('An error occurred')
      }
      AppHelpers.updateState()
   })
}

//for submitting OPT
OnBoardingFunction.submitOTP = (pin) => {
   // console.log(pin)
   //get the person email
   let acc = appState?.account
   //if the pin not of length return
   if (!pin || pin.length !== 4) return

   appState.loader = ReducerActions.loader.signUp
   AppHelpers.updateState()

   let sendData = {
      url: AppConfig.url.otp,
      method: 'POST',
      token: 'JJEKXMEITNDEJNGEJ355434JFJFDNT',
      body: { email: acc?.email, otp: pin }
   }
   //send the request to the sever
   AppHelpers.sendRequest(sendData, res => {
      // console.log(res)
      appState.loader = null
      if (res.error) {
         AppHelpers.flashMessage(res.error)
      } else if (res.status) {
         //if there's action
         if (acc?.action) {
            if (acc?.action === "change_password") {
               return AppHelpers.navigate('password')
            }
         }

         let newData = {
            ...appState?.account,
            ...res, status: 'success',
            is_confirmed: true,
         }

         AppHelpers.navigate('/app/', { replace: true })
         //modify the local storage
         AppHelpers.localStorageSave('_ud', newData);
      } else {
         AppHelpers.flashMessage('An error occurred')
      }
      AppHelpers.updateState()
   })
}



export default OnBoardingFunction;