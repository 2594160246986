import React from "react";
import { Link } from "react-router-dom";
import AppIcon from "../../assets/AppIcon";
import AppContext, { appState } from "../../js/Store";
import { AppButton, AppHeader, AppWallet, } from "../Components";
import WalletFunction from "../Wallet/_WalletFunction";
import Apphelpers from '../../js/Apphelpers'



const DefaultCompo = () => {
   let acc = appState.account
   console.log(acc)
   return (
      <div>
         <div className="container">
            <AppHeader />
            <div className="row mt-5">
               <div className="col-sm-6">
                  <div className="form-group mb-3">
                     <label htmlFor="name">Name*</label>
                     <input className="form-control" id="name" defaultValue={acc?.name} disabled />
                  </div>
                  <div className="form-group mb-3">
                     <label htmlFor="email">Email*</label>
                     <input className="form-control" id="email" type="email" defaultValue={acc?.email} disabled />
                  </div>
                  <div className="form-group mb-3">
                     <label htmlFor="address">Home Address*</label>
                     <input className="form-control" id="address" defaultValue={acc?.address} />
                  </div>
                  <div className="form-group mb-3">
                     <label htmlFor="Da">Date of Birth</label>
                     <input className="form-control" id="dob" type="date" defaultValue={acc?.dob} />
                  </div>
                  <div className="form-group mb-3">
                     <label htmlFor="address">State of Origin</label>
                     <input className="form-control" id="address" defaultValue={acc?.state} />
                  </div>
                  <div className="form-group mb-3">
                     <label htmlFor="address">City of Origin</label>
                     <input className="form-control" id="address" defaultValue={acc?.city} />
                  </div>
                  <div className="mt-5">
                     <AppButton title="UPDATE" />
                  </div>
               </div>
            </div>
         </div>
      </div>)
}



export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)