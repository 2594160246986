import React from "react";
import { Link } from "react-router-dom";
import AppIcon from "../../assets/AppIcon";
import AppContext, { appState } from "../../js/Store";
import { AppButton, AppHeader, AppWallet } from "../Components";
import WalletFunction from "../Wallet/_WalletFunction";
import Apphelpers from '../../js/Apphelpers'



const DefaultCompo = () => {
   let acc = appState.account
   React.useEffect(() => {
      WalletFunction.getWalletBalance()
   }, [])

   let dData = appState.results.wallets

   return (
      <div>
         <div className="container">
            <AppHeader />
            <div>
               <span className="text-gray">Hello!</span>
               <div style={{ fontSize: 35, fontWeight: '600' }}>{acc?.name}</div>
            </div>
            <AppWallet title="Wallet Balance" amount={Apphelpers.formatMoney(dData?.balance ?? 0)} />
            <div className="mt-4">
               <p className="m-0 text-gray">You have</p>
               <div className="job-holda">
                  <div className="ongoing-job-holda">
                     <span>0</span>
                     <span className="d-inline-block ml-4">Ongoing Job</span>
                  </div>
                  <div className="completed-job-holda">
                     <span>0</span>
                     <span className="d-inline-block ml-4">Completed Job</span>
                  </div>
               </div>
            </div>
            <div className="mt-4">
               <p className="m-0 text-gray">What would you like to do today?</p>
               <div className="rq-holda">
                  <img className="cursor-pointer" src={AppIcon.rqWorker} onClick={() => Apphelpers.navigate('/app/request')} />
                  <img className="cursor-pointer" src={AppIcon.inviteFrnd} onClick={() => Apphelpers.navigate('/app/rewards')} />
               </div>
            </div>
         </div>
      </div>
   )
}


export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)