import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppIcon from "../../assets/AppIcon";
import AppHelpers from "../../js/Apphelpers";
import AppContext, { appState } from "../../js/Store";
import { AppButton } from "../Components";
import OnBoardingFunction from "./_OnboardingFunction";

const RequiredField = () => (
   <span className="text-red">*</span>
)

const DefaultCompo = () => {
   const [cState, setCState] = React.useState({
      name: '', email: '', phone: '', user_type: 'client', seePass: false,
      refcode: '', password: '', cPassword: '', term: false
   })
   AppHelpers.navigate = useNavigate()
   return (
      <div>

         <div className="middle-page">
            <div className="login-m-page">
               <div className="text-center">
                  <img src={AppIcon.HollaLogo} className="app-logo" />
               </div>
               <div className="usertype-panel">
                  <div onClick={() => setCState(d => ({ ...d, user_type: 'client' }))}
                     className={cState?.user_type === "client" ? "active" : ""}>Client</div>
                  <div onClick={() => setCState(d => ({ ...d, user_type: 'worker' }))}
                     className={cState?.user_type === "worker" ? "active" : ""}>Worker</div>
               </div>
               <div className="form-group">
                  <div>
                     <label htmlFor="name" >First & Last Name<RequiredField /></label>
                     <input className="form-control" id="name" onChange={e => {
                        cState.name = e.target.value.trim()
                     }} style={{ marginBottom: 20 }} />
                  </div>
                  <div>
                     <label htmlFor="phone" >Phone Number<RequiredField /></label>
                     <input className="form-control" id="phone" onChange={e => {
                        cState.phone = e.target.value.trim()
                     }} style={{ marginBottom: 20 }} maxLength={11} type="tel" />
                  </div>
                  <div>
                     <label htmlFor="email" >Email<RequiredField /></label>
                     <input className="form-control" id="email" type="email" onChange={e => {
                        cState.email = e.target.value.trim()
                     }} style={{ marginBottom: 20 }} />
                  </div>
                  <div style={{ marginBottom: 20, position: 'relative' }}>
                     <label htmlFor="password">Password<RequiredField /></label>
                     <input className="form-control" onChange={e => {
                        cState.password = e.target.value.trim()
                     }} id="password" type={cState?.seePass ? "text" : "password"} />
                     <img src={AppIcon.passwordEye} className="rg-pass-eye" onClick={() => setCState(d => ({ ...d, seePass: !cState.seePass }))} />
                  </div>
                  <div>
                     <label htmlFor="refcode" >Referral Code</label>
                     <input className="form-control" maxLength={6} onChange={e => {
                        cState.email = e.target.value.trim()
                     }} style={{ marginBottom: 20 }} id="refcode" />
                  </div>
                  <div style={{ marginBottom: 40 }}>
                     <label>
                        <input type="checkbox" onChange={e => {
                           cState.term = e.target.checked
                        }} className="mr-3" />
                        <span>By Signing up you agree to our <a href="https://hollajobs.com/terms" target="_blank">Terms of Service</a> and<a href="https://hollajobs.com/privacy" target="_blank"> Privacy Policy,</a> which outline your rights and obligations with respect to your use of service and processing of data.</span>
                     </label>
                  </div>
                  <AppButton disabled={appState?.loader ? true : false} title={appState?.loader ? "Loading...." : "sign up"}
                     onClick={() => OnBoardingFunction.register(cState)} />
                  <div className="text-center mt-4">
                     <div>Already have an account?</div>
                     <Link to="/">LOG IN</Link>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}


export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)