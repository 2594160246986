import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
   AddBox, Check, Clear, DeleteOutline, SkipNextRounded,
   SkipPreviousRounded, ChevronRight, Edit, SaveAlt,
   FilterList, FirstPage, LastPage, Search,
   ArrowDownward, Remove, ViewColumn
} from '@material-ui/icons'
import AppIcon from "../assets/AppIcon";



export const TableMaterialIcon = {
   Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
   Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
   Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
   Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
   DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
   Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
   Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
   Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
   FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
   LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
   NextPage: React.forwardRef((props, ref) => <SkipNextRounded {...props} ref={ref} />),
   PreviousPage: React.forwardRef((props, ref) => <SkipPreviousRounded {...props} ref={ref} />),
   ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
   Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
   SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
   ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
   ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


export const AppButton = ({ style = {}, title = 'Submit', onClick, disabled = false }) => (
   <button className="app-btn" style={style} disabled={disabled}
      onClick={() => typeof onClick === "function" ? onClick() : {}}>{title}</button>
)


export const AppHeader = () => {

   return (
      <header>
         <div>
            <img src={AppIcon.HollaLogo} style={{ width: 75 }} />
         </div>
         <ul className="app-nav">
            <li>
               <NavLink to="/app/">Home</NavLink>
            </li>
            <li>
               <NavLink to="/app/wallets">Wallet</NavLink>
            </li>
            <li>
               <NavLink to="/app/jobs">Jobs</NavLink>
            </li>
            <li>
               <NavLink to="/app/accounts">Accounts</NavLink>
            </li>
         </ul>
      </header>
   )

}


export const AppWallet = ({ title, amount }) => (
   <div className="wallet-holder mt-4">
      <div className="wlt-div1">
         <p>{title}</p>
         <img src={AppIcon.WalletTopUp} style={{ width: 80, height: 25, marginLeft: 30 }} />
      </div>
      <span>N</span> <span style={{ fontWeight: '600', fontSize: 30 }}>{amount}</span>
   </div>
)


export const ReqBarStage = ({ active, title }) => (
   <div>
      <p>{title}</p>
      <div className="rq-bar-stage">
         <div className="active"></div>
         <div className={active > 1 ? 'active' : ''}></div>
         <div className={active > 2 ? 'active' : ''}></div>
         <div className={active > 3 ? 'active' : ''}></div>
      </div>
   </div>
)

export const AppModal = (props = { modalStyle: {}, rootStyle: {}, openModal: false }) => (
   props?.openModal ? <div className="app-modal" style={props.rootStyle}>
      <div className="app-modal-container" style={props.modalStyle}>
         {props.children}
      </div>
   </div> : <div></div>
)