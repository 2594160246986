import React from "react";
import { useLocation, useNavigate, useResolvedPath, useRoutes } from "react-router-dom";
import AppHelpers from "../../js/Apphelpers";
import AppContext, { appState } from "../../js/Store";
import OnBoardingFunction from "./_OnboardingFunction";


export default (e) => {
   AppHelpers.navigate = useNavigate()
   let useParam = useLocation()
   React.useEffect(() => {
      OnBoardingFunction.confirmLogin(useParam?.state?.redirect)
   }, [])
   return <div></div>

}