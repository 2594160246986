import React from "react";
import { Link } from "react-router-dom";
import AppIcon from "../../assets/AppIcon";
import AppContext, { appState } from "../../js/Store";
import { AppButton, AppHeader, AppWallet, } from "../Components";
import Apphelpers from '../../js/Apphelpers'



const DefaultCompo = () => {
   let acc = appState.account
   // console.log(acc)
   return (
      <div>
         <div className="container">
            <AppHeader />
            <div className="row mt-5">
               <div className="col-md-6">
                  <img src={AppIcon.inviteReward} />
                  <p className="text-blue" style={{
                     fontWeight: '500', maxWidth: 300, margin: '20px auto', textAlign: 'center',
                     fontSize: 26, lineHeight: '28.78px',
                  }}>Invite your friends/family and get rewarded</p>
                  <div>
                     <span>Your Referral Code</span>
                     <p style={{ padding: 20, background: '#E6EEFF', borderRadius: 5, fontWeight: 'bold' }}>{acc?.refcode}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>)
}



export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)