const AppIcon = {}

AppIcon.HollaLogo = require('./images/logo.png')
AppIcon.WalletTopUp = require('./images/wallet-topup.png')
AppIcon.rqWorker = require('./images/rq-worker.png')
AppIcon.inviteFrnd = require('./images/invite-frnd.png')
AppIcon.successIcon = require('./images/success-icon.png')
AppIcon.failedIcon = require('./images/failed-icon.png')
AppIcon.arrowDown = require('./images/arrow-down.png')
AppIcon.passwordEye = require('./images/password-eye.png')
AppIcon.loaderGif = require('./images/loader.gif')
AppIcon.inviteReward = require('./images/invite-reward.png')
AppIcon.HollaLoader = require('./images/holla-loader.gif')







export default AppIcon;