import MaterialTable from 'material-table'
import React from 'react'
import AppIcon from '../../assets/AppIcon'
import AppHelpers from '../../js/Apphelpers'
import AppContext, { appState } from '../../js/Store'
import { AppHeader, TableMaterialIcon } from '../Components'
import RequestFunction from './_RequestFunction'


const JobStatusText = (statusCode, userType) => {
   switch (statusCode) {
      case 0: return userType === "client" ? "Waiting for the worker to accept request" : "New job request"
      case 1: return userType === "client" ? "Worker accepted your request" : "Client is waiting for your invoice "
      case 2: return userType === "client" ? "Worker sent invoice" : "Waiting for client to accept invoice and make payment"
      case 3: return userType === "client" ? "Payment Successful. Waiting for worker to start job" : "Client made payment, proceed to start job"
      case 4: return userType === "client" ? "Worker started job, proceed to confirm" : "Waiting for client to confirm start job"
      case 5: return "Job in progress"
      case 6: return userType === "client" ? "Worker ended job, proceed to confirm" : "Waiting for client to confirm end job"
      case 7: return "Job completed"
      default: return "Job Cancelled"
   }
}

const runStatusColor = (code) => {
   switch (code) {
      case 0:
         return {
            style: {
               backgroundColor: "#F3F3F3",
               color: "#3c3c3c"
            },
            text: "Pending"
         }
      case 7:
         return {
            style: {
               backgroundColor: "#E9FFF3",
               color: "#00C914"
            },
            text: "Completed"
         }
      case 10:
         return {
            style: {
               backgroundColor: "#FF3500",
               color: "#FFF"
            },
            text: "Cancelled"
         }
      default:
         return {
            style: {
               backgroundColor: "#E9F6FF",
               color: "#1A77BA"
            },
            text: "Ongoing"
         }
   }
}

const JobCard = ({ data, userData, JobStatus, userType }) => (
   <div className="col-md-6 col-lg-4">
      <div className="job-card mb-3">
         <div className="job-header">
            <div className="job-dp">
               <img src={userData?.avatar} />
            </div>
            <div>
               <div>{userData?.name}</div>
               <div>{userType === "worker" ? "Client" : "Worker"}</div>
            </div>
         </div>
         <div className="job-details">
            <div>
               <span className="text-gray mr-3 ">Job Title:</span>
               <span className="text-capitalize">{data?.title}</span>
            </div>
            <div>
               <span className="text-gray mr-3">Status:</span>
               <span className="text-blue">{JobStatus}</span>
            </div>
         </div>
         <div className="job-footer">
            <div>{AppHelpers.calculatePostDate(data?.prefer_date)}</div>
            <div className="job-status" style={runStatusColor(data?.status).style}>{runStatusColor(data?.status).text}</div>
         </div>
      </div>
   </div>
)


const DefaultCompo = () => {
   React.useEffect(() => {
      RequestFunction.getJobs()
   }, [])
   let dData = appState?.results?.jobs
   let acc = appState?.account
   // console.log(dData)
   return (
      <div>
         <div className="container">
            <AppHeader />
            {dData?.getJobs instanceof Array && dData?.getJobs?.length > 0 ?
               <div className="row">
                  {dData?.getJobs instanceof Array && dData?.getJobs?.length > 0 ?
                     dData?.getJobs?.map((e, i) => <JobCard key={i} data={e} JobStatus={JobStatusText(e?.status, acc?.login_as)} userType={acc?.login_as}
                        userData={acc?.login_as === "worker" ? e.client_data : e?.worker_data} />) : <div>No Record to display</div>}
               </div> :
               <MaterialTable
                  data={dData?.getJobs ?? []}
                  isLoading={!(dData?.getJobs instanceof Array)}
                  style={{ boxShadow: 'none' }}
                  icons={TableMaterialIcon}
                  actions={[]}
                  columns={[
                     {
                        title: "Amount",
                     },
                     {
                        title: "Description",
                     },
                     {
                        title: "Method",
                        field: "method",
                     },
                  ]}
                  options={{
                     search: false,
                     toolbar: false,
                     pageSize: 10,
                     pageSizeOptions: [10, 20, 30, 40, 50],
                     headerStyle: { fontWeight: 'bold', }
                  }}
                  title="Jobs"
               />}
         </div>
      </div>
   )
}


export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)