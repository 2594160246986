import React, { createContext, useReducer } from 'react';
import Reducers from './Reducers';

const AppContext = createContext();
export let dispatcher;
export let appState;

export const AppProvider = (props) => {
   const initialState = {
      cUpdate: Math.random(),
      setupConfig: {},
      loader: null,
      results: {
         wallets: {},
         jobs: {}
      },
      rq: {},
      account: {},
      is_login: false,
   }
   const [state, dispatch] = useReducer(Reducers, initialState)
   dispatcher = dispatch
   appState = state
   return <AppContext.Provider value={{ state }}>
      {props.children}
   </AppContext.Provider>
}
export default AppContext
