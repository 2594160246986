import AppConfig from "../../js/AppConfig"
import AppHelpers from "../../js/Apphelpers"
import ReducerActions from "../../js/ReducerActions"
import { appState } from "../../js/Store"


const RequestFunction = {}

RequestFunction.getJobs = () => {
   let sendData = {
      url: AppConfig.url.getJobs
   }

   let dData = appState?.results?.jobs
   //if there's data stop the request
   if (dData?.getJobs instanceof Array) return

   //send the request to the sever
   AppHelpers.sendRequest(sendData, (res, error) => {
      //if there's an error
      if (res instanceof Array) {
         appState.results.jobs.getJobs = res
      } else if (res?.error) {
         return AppHelpers.flashMessage(res.error)
      } else {
         return AppHelpers.flashMessage("An error occurred try again later")
      }
      AppHelpers.updateState()
   })
}


let TypingTimer;
RequestFunction.SearchGoogleLocation = (setCState, q) => {
   // if no string, clear the input and display saved addresses
   if (!q) {
      clearTimeout(TypingTimer)
      TypingTimer = null
      setCState(prev => ({ ...prev, loader: false }))
      return
   }
   //show loader if first typing
   if (!TypingTimer) {
      setCState(prev => ({ ...prev, input: q, loader: true }))
   }
   //if there's timer clear
   if (TypingTimer) {
      clearTimeout(TypingTimer)
   }

   TypingTimer = setTimeout(e => {
      AppHelpers.sendRequest({ url: `${AppConfig.url.googlePlaceSearch}?q=${q}`, method: 'GET' }, res => {
         clearTimeout(TypingTimer)
         TypingTimer = null //clear it to null
         setCState(prev => ({
            ...prev, addrList: res.predictions,
            loader: false, showAddrModal: true
         }))
      })
   }, 1300)
}

//for getting geo coordinates of an address
RequestFunction.getGeoCoordinates = (txt, callback) => {
   AppHelpers.sendRequest({ url: `${AppConfig.url.googleGeocodingSearch}?q=${txt}`, }, res => {
      //check if there's result
      if (res?.results instanceof Array && res?.results?.length > 0) {
         callback({
            longitude: res.results[0].geometry?.location?.lng,
            latitude: res.results[0].geometry?.location?.lat,
            address: txt,
         })
      } else {
         callback({})
      }
   })
}

RequestFunction.requestDataToState = (jobData = {
   address: '', geo: {}, date: '', description: ''
}) => {
   //if there's no valid data
   if (!jobData?.address) {
      return AppHelpers.flashMessage("Please search and select a job location")
   }
   if (!jobData?.date) {
      return AppHelpers.flashMessage("Please a preferred date")
   }
   appState.rq.data_tem = jobData
   //if there's navigation
   AppHelpers.navigate('/app/request/skill-category')
   // console.log(jobData)
}

//get getting skills
RequestFunction.getSkills = (q, component, refreshStopper, callback) => {
   let sendData = {
      url: `${AppConfig.url.getSkill}?component=${component ?? ''}&q=${q ?? ''}`,
      method: 'GET',
   }
   //send the data to the server
   AppHelpers.sendRequest(sendData, res => {
      // appState.skill = res.error ?? res
      //stop a freshcontrol if provided
      if (typeof refreshStopper === 'function') refreshStopper()
      if (typeof callback === 'function') callback(res)
      // AppHelpers.updateState.HomeState()
   })
}


//for searching for workers
RequestFunction.searchWorker = async (jobData, navigation) => {
   // res = typeof res === "object" ? res : JSON.parse(res)
   // appState.r.data_tem.skill = skill
   // let jobData = appState.r.data_tem
   // console.log(jobData)
   //add the loader and update state
   // appState.loader = ReducerAction.loader.searchWorker
   // return console.log(jobData)
   AppHelpers.updateState()
   // return
   let sendData = {
      url: AppConfig.url.searchWorker,
      method: "POST",
      body: {
         // longitude: "3.616016",
         // latitude: "6.447283",
         longitude: String(jobData?.geo?.longitude),
         latitude: String(jobData?.geo?.latitude),
         skill: jobData?.skill
      }
   }
   AppHelpers.sendRequest(sendData, res => {
      // console.log(res)
      if (res instanceof Array) {
         if (res.length === 0) {
            AppHelpers.flashMessage("No worker available at the moment")
         } else {
            appState.rq.worker_list = res
            AppHelpers?.navigate('/app/request/worker-list')
         }
      } else {
         AppHelpers.flashMessage("Could not load available workers")
      }
      appState.loader = null
      AppHelpers.updateState()
   })

}

RequestFunction.addSelectedWorkerToState = (data) => {
   // console.log(data)
   // console.log(appState?.rq)
   appState.rq.data_tem.worker_data = {
      avatar: data?.avatar, name: data?.name, email: data?.email,
      user_id: data?.user_id, phone: data?.phone, location: data?.location,
      skill: data?.skill?.name
   }

   AppHelpers.navigate('/app/request/request-preview')
   console.log(appState.rq.data_tem)
}


//for searching for workers
RequestFunction.requestAWorker = async (jobData) => {
   // return
   let sendData = {
      url: AppConfig.url.requestWorker,
      method: "POST",
      body: {
         skill: jobData?.worker_data?.skill,
         latitude: String(jobData?.geo?.latitude),
         longitude: String(jobData?.geo?.longitude),
         worker_id: jobData?.worker_data?.user_id,
         address: jobData?.address,
         description: jobData?.description,
         prefer_date: jobData?.date
      }
   }

   appState.loader = ReducerActions.loader.signUp
   AppHelpers.updateState()

   AppHelpers.sendRequest(sendData, res => {
      // console.log(res)
      if (res?.status) {
         appState.rq.data_tem.status_code = 1
      } else {
         AppHelpers.flashMessage("Could not load available workers")
      }
      appState.loader = null
      AppHelpers.updateState()
   })
}






export default RequestFunction;