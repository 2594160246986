const AppConfig = {}
AppConfig.url = {}
AppConfig.credential = {}
AppConfig.credential.GOOGLE_API_KEY = 'AIzaSyBeXRut9MrFHntIMRWmwoSS5Dfa6-jIJXE'

let userService = 'https://api.hollajobs.com/userservice'
let walletService = 'https://api.hollajobs.com/walletservice'
let requestService = 'https://api.hollajobs.com/reqservice'


AppConfig.url.resendOtp = `${userService}/auth/resend-otp`
AppConfig.url.otp = `${userService}/auth/otp`
AppConfig.url.login = `${userService}/auth/login`
AppConfig.url.getSkill = `${userService}/auth/skills`
AppConfig.url.googlePlaceSearch = `${userService}/auth/google-place-search`
AppConfig.url.googleGeocodingSearch = `${userService}/auth/google-geocoding-search`
AppConfig.url.register = `${userService}/auth/register`
AppConfig.url.getWalletTrans = `${walletService}/wallets/transaction`
AppConfig.url.getWalletBalance = `${walletService}/wallets/balance`
//Requst service
AppConfig.url.getJobs = `${requestService}/jobs/get-jobs`
AppConfig.url.searchWorker = `${requestService}/jobs/search-worker`
AppConfig.url.requestWorker = `${requestService}/jobs/request-worker`



export default AppConfig;