import { NotificationManager } from 'react-notifications';
import ReducerActions from "./ReducerActions"
import { dispatcher } from "./Store"
import AppConfig from '../js/AppConfig'

const AppHelpers = {}

AppHelpers.userData = {}
AppHelpers.getUserData = () => {
   if (!AppHelpers.userData || !AppHelpers.userData.token) {
      let d = JSON.parse(localStorage.getItem('_ud'))
      AppHelpers.userData = d
   }
   return AppHelpers.userData
}

AppHelpers.navigate = () => { }

//for timing out request
const TimeoutRequest = (ms, promise) => {
   let returnResult = false
   return new Promise((resolve, reject) => {
      //create a timeout
      let timeoutId = setTimeout(() => {
         // console.log("request time up")
         reject(new Error("promise timeout"))
      }, ms);
      // when the promise resolves
      promise.then((res) => {
         clearTimeout(timeoutId);
         //if the request resolve within the time range
         if (returnResult === false) {
            resolve(res);
         }
      }, (err) => {
         //when the timeout cancels the request
         returnResult = true
         clearTimeout(timeoutId);
         reject(err);
      }).catch(e => {
         //when the promise reject throw error
         returnResult = true
         reject(e)
      })
   })
}

//for sending request to the server
AppHelpers.sendRequest = (Obj, callback) => {
   if (!Obj instanceof Object) return
   if (Obj.url === undefined) return
   // console.log(AppHelpers.isConnected)

   var headers = new Headers();
   headers.append('Content-Type', 'application/json');
   headers.append('Authorization', Obj.token ?? "Bearer " + AppHelpers?.userData?.jToken)

   let option = { headers: Obj.headers ?? headers, method: Obj.method ?? 'GET' }
   option.body = Obj.body ? JSON.stringify(Obj.body) : Obj.json ? Obj.json : null
   // option.mode = 'no-cors'

   //send the request and wait for 30secs
   TimeoutRequest(Obj.timeout ?? 30000, fetch(Obj.url, option))
      .then(res => res.json().then(data => callback(data)))
      .catch((error) => {
         // console.log("promise time", error)
         callback({ "error": error })
      })
}

//for updating the app state
AppHelpers.updateState = () => dispatcher({
   type: ReducerActions.updateState
})

//for displaying alert
AppHelpers.flashMessage = (message, type) => {
   if (typeof message !== "string") return
   switch (type) {
      case 'info':
         NotificationManager.info(message);
         break;
      case 'success':
         NotificationManager.success(message);
         break;
      case 'warning':
         NotificationManager.warning(message);
         break;
      default:
         NotificationManager.error(message);
   }
}

AppHelpers.localStorageSave = (key, value) => {
   localStorage.setItem(key, JSON.stringify(value))
}


let MonthDays = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
AppHelpers.MonthDays = MonthDays;

AppHelpers.calculatePostDate = (date, format) => {
   if (!date) return
   let DateNow = new Date() // current date
   // let postedDate = new Date(date); // date posted
   let postedDate = new Date(AppHelpers.timeZone(date)); // date posted
   let postedTime = date.indexOf('T') > -1 ? date.substr(date.indexOf("T") + 1, 5) : "" //get the time
   let postedMnth = MonthDays[postedDate.getMonth()] //get the month
   let postedYear = postedDate.getFullYear(); //get the year
   let postedDay = postedDate.getDate() //get the date of the month
   let dnDay = DateNow.getDate()
   let rDate = null
   let sDate = ((DateNow - postedDate) / 1000) //get the seconds of in the date
   let outputDate = null

   if (sDate >= 86400) { // if days
      rDate = parseInt(Math.round(sDate / 86400))
      switch (format) {
         case 'date-only':
            outputDate = rDate === 1 ? 'Yesterday' : `${postedMnth} ${postedDay}, ${postedYear}`
            break;
         default:
            outputDate = rDate === 1 ? 'Yesterday ' + postedTime : `${postedMnth} ${postedDay}, ${postedYear} ${postedTime}`
      }
   } else if (sDate >= 3600) { //if hours
      rDate = parseInt(Math.round(sDate / 3600))
      switch (format) {
         case 'date-only':
            outputDate = dnDay === postedDay ? 'Today' : 'Yesterday'
            break;
         default:
            outputDate = rDate + ' hr ago'
      }
   } else if (sDate >= 60) { //if minuntes
      rDate = parseInt(Math.round(sDate / 60))
      switch (format) {
         case 'date-only':
            outputDate = dnDay === postedDay ? 'Today' : 'Yesterday'
            break;
         default:
            outputDate = rDate + ' min ago'
      }
   } else { //if seconds
      rDate = Math.ceil(sDate)
      switch (format) {
         case 'date-only':
            outputDate = dnDay === postedDay ? 'Today' : 'Yesterday'
            break;
         default:
            outputDate = sDate < 1 ? '1 sec ago' : rDate + ' sec ago'
      }
   }
   return outputDate
}

//for getting the time zone
AppHelpers.timeZone = (date) => {
   var dbDate = new Date(date)
   var timeOffsetInMS = dbDate.getTimezoneOffset() * 60000;
   dbDate.setTime(dbDate.getTime() - timeOffsetInMS);
   return date
}


//for formating a value to money
AppHelpers.formatMoney = (value) => {
   if (!value || isNaN(value)) return value;
   return String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}



export default AppHelpers;
