import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppIcon from "../../assets/AppIcon";
import AppHelpers from "../../js/Apphelpers";
import AppContext, { appState } from "../../js/Store";
import { AppButton } from "../Components";
import OnBoardingFunction from "./_OnboardingFunction";



const DefaultCompo = () => {
   const [cState, setCState] = React.useState({ email: '', password: '', user_type: 'client', seePass: false })
   AppHelpers.navigate = useNavigate()
   return (
      <div>
         <div className="middle-page">
            <div className="login-m-page">
               <div className="text-center">
                  <img src={AppIcon.HollaLogo} className="app-logo" />
               </div>
               <div className="usertype-panel">
                  <div onClick={() => setCState(d => ({ ...d, user_type: 'client' }))}
                     className={cState?.user_type === "client" ? "active" : ""}>Client</div>
                  <div onClick={() => setCState(d => ({ ...d, user_type: 'worker' }))}
                     className={cState?.user_type === "worker" ? "active" : ""}>Worker</div>
               </div>
               <div className="form-group">
                  <div>
                     <label htmlFor="email" >Email*</label>
                     <input className="form-control" id="email" onChange={res => {
                        cState.email = res.target.value?.trim()
                     }} style={{ marginBottom: 20 }} />
                  </div>
                  <div style={{ marginBottom: 40, position: 'relative' }}>
                     <label htmlFor="password" >Password*</label>
                     <input className="form-control" id="password" onChange={res => {
                        cState.password = res.target.value?.trim()
                     }} type={cState?.seePass ? "text" : "password"} />
                     <img src={AppIcon.passwordEye} className="rg-pass-eye" onClick={() => setCState(d => ({ ...d, seePass: !cState.seePass }))} />
                  </div>
                  <AppButton disabled={appState?.loader ? true : false} title={appState?.loader ? "Loading...." : "Log In"} onClick={() => OnBoardingFunction.processLogin(cState)} />
                  <div className="text-center mt-4">
                     <div>Don't have an account?</div>
                     <Link to="/register">SIGN UP</Link>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}


export default () => (
   <AppContext.Consumer>
      {() => <DefaultCompo />}
   </AppContext.Consumer>
)